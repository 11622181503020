import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Formatter from "../utils/Formatter";

import { ReactComponent as IconPlus } from "../styles/images/IconPlus.svg";
import { ReactComponent as IconRight } from "../styles/images/IconRight.svg";

export default ({ accountInfo, drawerOpenCallBack }) => {
  const { t } = useTranslation();
  const [myTotalVotes, setMyTotalVotes] = useState();
  const [myTotalFrozen, setMyTotalFrozen] = useState();

  useEffect(() => {
    if (accountInfo) {
      let voteList = accountInfo.vote_infos;
      let myTotalVotes = voteList.reduce(
        (a, b) => {
          return {
            votes: a.votes + b.votes
          };
        },
        { votes: 0 }
      );
      setMyTotalVotes(myTotalVotes.votes);
      let sumFrozen = accountInfo.frozen_balances.reduce(
        (a, b) => a + (b["amount"] || 0),
        0
      );
      setMyTotalFrozen(sumFrozen);
    }
  }, [accountInfo]);

  return (
    <section className="panel voteManage">
      <p className="head jcsb">
        {t("我的投票")}
        {/* 路由跳转 */}
        <Link to="/iostvoter/myvotes/current">{t("管理")}</Link>
      </p>

      <p className="panelAmount">
        {!isNaN(myTotalVotes)
          ? Formatter.voteNumberFormat(myTotalVotes) + " " + t("票")
          : t("查询中...")}
      </p>

      {accountInfo && accountInfo.frozen_balances.length > 0 && (
        <Link to="/iostvoter/myvotes/history" className="status jcsb">
          <div className="aic">
            <span className="dot" />
            {t("{{v}} 票正在赎回", {
              v: Formatter.voteNumberFormat(myTotalFrozen)
            })}
          </div>
          <IconRight />
        </Link>
      )}

      <button className="large" onClick={() => drawerOpenCallBack(true)}>
        <IconPlus />
        {t("新投票")}
      </button>
    </section>
  );
};
