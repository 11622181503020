import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Formatter from "../utils/Formatter";

export default ({ accountInfo }) => {
  const { t, i18n } = useTranslation();
  const [frozenBalances, setFrozenBalances] = useState();

  useEffect(() => {
    if (accountInfo && accountInfo.hasOwnProperty("frozen_balances")) {
      let fb = accountInfo.frozen_balances;
      setFrozenBalances(fb);
    }
  }, [accountInfo]);

  return (
    <ul className="redeemHistory">
      {frozenBalances &&
        frozenBalances.map((item, key) => (
          <li key={key}>
            <div className="amount">
              <label>{t("数量 (IOST)")}</label>
              <p>{Formatter.iostFormat(item.amount)}</p>
            </div>
            <p className="time">
              {t("到账时间：")}{" "}
              {new Date(Number(item.time) / 1000000).toLocaleString(
                i18n.language === "zh" ? "zh" : "en",
                { hour12: false }
              )}
            </p>
          </li>
        ))}
    </ul>
  );
};
