import React from "react";

import { ReactComponent as IconStatusFailed } from "../styles/images/IconStatusFailed.svg";
import { ReactComponent as IconStatusPending } from "../styles/images/IconStatusPending.svg";
import { ReactComponent as IconStatusSuccess } from "../styles/images/IconStatusSuccess.svg";

export default ({ status, text }) => {
  return (
    <div className="statusIcon">
      <div className="icon">
        {status === "failed" && <IconStatusFailed className="failed" />}
        {status === "pending" && <IconStatusPending className="pending" />}
        {status === "success" && <IconStatusSuccess className="success" />}
      </div>
      <p className="copy">{text}</p>
    </div>
  );
};
