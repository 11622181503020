// import Float64 from '../libjs/float64'
import IostHandler from './IostHandler'
import Vote from './Vote'

// const PRE_PRODUCER_THRESHOLD = "2100000";

export default class VoteProducer {
  constructor () {
    this.contract = 'vote_producer.iost'
    this.iostHandler = new IostHandler()
    this.vote = new Vote()
  }

  async getVoteId () {
    return await this.iostHandler.getTable(this.contract, 'voteId')
  }

  async getVoterListByAccount (account) {
    return await this.iostHandler.getTable(this.contract, `v_${account}`)
  }

  async getProducerList () {
    return await this.iostHandler.getTable(this.contract, 'producerMap')
  }

  async getProducerKeyMapList () {
    return await this.iostHandler.getTable(this.contract, 'producerKeyMap')
  }

  async getVoterCoefByProducer (producer) {
    return await this.iostHandler.getTable(this.contract, 'voterCoef', producer)
  }

  async getProducerDetail (producer) {
    return await this.iostHandler.getTable(this.contract, 'producerTable', producer)
  }

  async getProducerByPublicKey (publicKey) {
    return await this.iostHandler.getTable(this.contract, 'producerKeyToId', publicKey)
  }

  async getProducerScoreList () {
    return await this.iostHandler.getTable(this.contract, 'producerScores')
  }

  async getProducerNumber () {
    return await this.iostHandler.getTable(this.contract, 'producerNumber')
  }

  async getCurrentProducerList () {
    return await this.iostHandler.getTable(this.contract, 'currentProducerList')
  }

  async getPendingProducerList () {
    return await this.iostHandler.getTable(this.contract, 'pendingProducerList')
  }

  async getWaitingRemoveList () {
    return await this.iostHandler.getTable(this.contract, 'waitingRemoveList')
  }

  // async getCandidateCoef () {
  //   let candCoef = await this.iostHandler.getTable(this.contract, 'candCoef')
  //   if (!candCoef) {
  //     candCoef = "0";
  //   }
  //   return new Float64(candCoef);
  // }
  //
  // async getCandidateMask (account) {
  //   let candMask = await this.iostHandler.getTable(this.contract, 'candMask', account)
  //   if (!candMask) {
  //     candMask = "0";
  //   }
  //   return new Float64(candMask);
  // }
  //
  // async getCandidateBonus (account) {
  //   let voteId = await this.getVoteId();
  //   let candKey = await this.vote.getOption(voteId, account)
  //   candKey = new Float64(candKey.votes)
  //   if (candKey.lt(PRE_PRODUCER_THRESHOLD)) {
  //     candKey = new Float64(0);
  //   }
  //   let candCoef = await this.getCandidateCoef();
  //   let candMask = await this.getCandidateMask(account);
  //   let earning = candCoef.multi(candKey).minus(candMask);
  //
  //   return earning;
  // }

  // async getVoterCoef (producer) {
  //   let voterCoef = await this.iostHandler.getTable(this.contract, 'voterCoef', producer)
  //   if (!voterCoef) {
  //     voterCoef = "0";
  //   }
  //   return new Float64(voterCoef);
  // }
  //
  // async getVoterMask (voter, producer) {
  //   let voterMask = await this.iostHandler.getTable(this.contract, `v_${producer}`, voter)
  //   if (!voterMask) {
  //     voterMask = "0";
  //   }
  //   return new Float64(voterMask);
  // }

  async getVoterBonus1 (voter) {
    let userVoteInfo = await this.iostHandler.getVoterBonus(voter);

    return userVoteInfo;
  }

  // async getVoteList (voteId, voter) {
  //   let userVotes = await this.vote.getVote(voteId, voter);
  //   return userVotes;
  // }
  //
  // async getVoterBonus (voteId, voter) {
  //   let userVotes = await this.vote.getVote(voteId, voter);
  //   let earnings = new Float64(0);
  //   let totalVotes = new Float64(0);
  //   for (const v of userVotes) {
  //     let voterCoef = await this.getVoterCoef(v.option);
  //     let voterMask = await this.getVoterMask(voter, v.option);
  //     let earning = voterCoef.multi(v.votes).minus(voterMask);
  //     earnings = earnings.plus(earning);
  //     totalVotes = totalVotes.plus(v.votes)
  //     v.earning = earning.toFixed(8)
  //     v.voterCoef = voterCoef
  //     v.voterMask = voterMask
  //   }
  //   return {
  //     userVotes,
  //     earnings: earnings.toFixed(8),
  //     totalVotes: totalVotes.toFixed(8)
  //   };
  // }

}
