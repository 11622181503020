import React from "react";
import { useTranslation } from "react-i18next";

import Modal from "./common/Modal";
import Hash from "../components/Hash";
import Formatter from "../utils/Formatter";
import StatusIcon from "./StatusIcon";

import { ReactComponent as IconArrow } from "../styles/images/IconArrow.svg";
import { ReactComponent as IconIOST } from "../styles/images/IconIOST.svg";

export default ({ onVote, onCancel, userVoteInfo, voteBonus, show, transaction }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      title={t("一键复投")}
      close={transaction.status !== "pending" ? () => onCancel() : null}
      className="revote"
    >
      {transaction.status === "noTx" && (
        <>
          <div className="intro description">
            {t("简介：帮你将最新分红的 IOST，再次投票给发放分红的节点。")}
          </div>
          <div className="bonusList">
            <header className="jcsb">
              <span>{t("数量 (IOST)")}</span>
            </header>
            <ul>
              <li className="detail jcsb">
                <span>{voteBonus}</span>
                <IconArrow className="to" />
                <span>{t("分红节点")}</span>
              </li>
            </ul>
          </div>
          {/* <div className="bonusList">
            <header className="jcsb">
              <span>{t("数量 (IOST)")}</span>
              <span>{t("分红节点")}</span>
            </header>
             <ul>
              {Object.keys(userVoteInfo.detail).map((key, index) => {
                return (
                  <li className="detail jcsb" key={index}>
                    <span>
                      {Formatter.iostFormat(userVoteInfo.detail[key].bonus)}
                    </span>
                    <IconArrow className="to" />
                    <span>{t("nodeName." + key)}</span>
                  </li>
                );
              })}
            </ul>
          </div> */}
          <button className="large" onClick={() => onVote()}>
            {t("确认")}
          </button>
        </>
      )}

      {transaction.status === "success" && (
        <>
          <StatusIcon status="success" text={t("复投成功")} />

          <Hash hash={Formatter.txToUrl(transaction.result.tx_hash)} />
          <footer>
            <button className="large" onClick={() => onCancel()}>
              {t("完成")}
            </button>
          </footer>
        </>
      )}

      {transaction.status === "pending" && (
        <StatusIcon status="pending" text={t("正在复投...")} />
      )}

      {transaction.status === "failed" && (
        <>
          <StatusIcon status="failed" text={t("复投失败")} />

          {transaction.failed !== "nogas" ? (
            <Hash hash={Formatter.txToUrl(transaction.failed.tx_hash)} />
          ) : (
            <div className="alertIost">
              <p className="alert aic">
                <IconIOST />
                {t("账号 Gas 不足")}
              </p>
              <div className="detail description">
                <p>
                  {t(
                    "大约需要抵押 {{v}} IOST 才能完成此操作（如不足请适量增加）。",
                    {
                      v: userVoteInfo
                        ? 3 + Object.keys(userVoteInfo.detail).length * 2
                        : 5
                    }
                  )}
                </p>
                <p>
                  {t("TokenPocket 用户可前往「首页 - 资源」功能中完成抵押。")}
                </p>
              </div>
            </div>
          )}

          <footer>
            <button className="large" onClick={() => onCancel()}>
              {t("完成")}
            </button>
          </footer>
        </>
      )}
    </Modal>
  );
};
