import React, {useState} from 'react';
import Carousel from "nuka-carousel";
import { useTranslation } from "react-i18next";

import bannerPoolZH from "../styles/images/banner-pool-zh.png";
import bannerPoolEN from "../styles/images/banner-pool-en.png";
import bannerIostabcZH from "../styles/images/banner-iostabc-zh.png";
import bannerIostabcEN from "../styles/images/banner-iostabc-en.png";

export default () => {
  const { t, i18n } = useTranslation();
  const [isAutoplayReverse, setIsAutoplayReverse] = useState(false);

  function cyclePlay(slideIndex) {
    if(slideIndex >= 1) {
      setIsAutoplayReverse(true)
    } else {
      setIsAutoplayReverse(false)
    }
  }

  return (
    <Carousel
      autoplay
      autoplayReverse={isAutoplayReverse}
      afterSlide={slideIndex => cyclePlay(slideIndex)}
      renderCenterLeftControls={() => ( null )}
      renderCenterRightControls={() => ( null )}
      transitionMode={"scroll3d"}
    >
      <a href="https://abct.iostabc.com/" target="_blank">
        <div className="panelBanner">
          <img
              src={i18n.language === "zh" ? bannerIostabcZH : bannerIostabcEN}
              alt="IOSTABC"
              width="100%"
              height="auto"
          />
        </div>
      </a>

      <a href="https://pool.liebi.com/">
        <div className="panelBanner">
          <img
            src={i18n.language === "zh" ? bannerPoolZH : bannerPoolEN}
            alt="猎币矿池"
            width="100%"
            height="auto"
          />
        </div>
      </a>
    </Carousel>
  );
};
