import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Formatter from "../utils/Formatter";

import Modal from "../components/common/Modal";
import StatusIcon from "./StatusIcon";
import Hash from "../components/Hash";

import { ReactComponent as IconGlobe } from "../styles/images/IconGlobe.svg";
import { ReactComponent as IconArrow } from "../styles/images/IconArrow.svg";
import { ReactComponent as IconIOST } from "../styles/images/IconIOST.svg";
import { ReactComponent as IconOfficial } from "../styles/images/IconOfficial.svg";

export default ({
  onConfirm,
  onCancel,
  balance,
  currentCandidate,
  transaction,
  show
}) => {
  const { t } = useTranslation();
  const [votes, setVotes] = useState();
  const [showDividendList, setShowDividendList] = useState(false);

  useEffect(() => {
    if (show) {
      calculate(votes);
    } else {
      setTimeout(() => {
        setShowDividendList(false);
      }, 200);
    }
  });

  function calculate(votes) {
    setVotes(votes);
  }

  return (
    <Modal
      show={show}
      title={t("新投票")}
      close={transaction.status !== "pending" ? () => onCancel() : null}
    >
      {transaction.status === "noTx" && (
        <>
          <div
            className="nodeInfo"
            onClick={() => setShowDividendList(!showDividendList)}
          >
            <div className="name aic">
              <IconGlobe />
              <div>
                <p className="aic">
                  {t("nodeName." + currentCandidate.candidate) === t("nodeName.") + currentCandidate.candidate ?
                    t(currentCandidate.candidate) :
                    t("nodeName." + currentCandidate.candidate)}
                  {currentCandidate.candidate === "iostliebibp" && (
                    <span className="official">
                      <IconOfficial />
                    </span>
                  )}
                </p>
                <p className="description">{currentCandidate.candidate}</p>
              </div>
            </div>
          </div>

          <div className="input">
            <label>{t("输入你想投的票数")}</label>

            <input
              className="large"
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              value={votes || ""}
              onChange={event => {
                const votes = event.target.value;
                calculate(votes);
              }}
              onBlur={() => {
                window.scrollTo(0, 0);
              }}
            />

            <p className="tips jcsb">
              <span>
                <span className="description">{t("可投")} </span>
                <span className="balance">
                  {Formatter.balanceNumberFormat(balance)}
                </span>
              </span>
              <span className="action" onClick={() => calculate(balance)}>
                {t("全部投票")}
              </span>
            </p>
          </div>

          {/*<div className="calc">*/}
          {/*<label>{t("预测 30 天收益")}</label>*/}
          {/*<div className="amount">*/}
          {/*{votes > 0 ? (*/}
          {/*<>{Formatter.iostFormat(voteIncome * 30)} IOST</>*/}
          {/*) : (*/}
          {/*t("请先输入 ...")*/}
          {/*)}*/}
          {/*</div>*/}
          {/*<p className="avg">*/}
          {/*{t("平均每天 {{v}}", {*/}
          {/*v: votes > 0 ? Formatter.iostFormat(voteIncome) : "..."*/}
          {/*})}*/}
          {/*</p>*/}
          {/*</div>*/}
          <footer>
            <button className="large" onClick={() => onConfirm(votes)}>
              {t("投票")}
            </button>
          </footer>
        </>
      )}

      {transaction.status === "success" && (
        <>
          <StatusIcon
            status="success"
            text={t("成功给 {{nodeName}} 投 {{amount}} 票", {
              nodeName:
                t("nodeName." + transaction.producer) === t("nodeName.") + transaction.producer ?
                  t(transaction.producer) :
                  t("nodeName." + transaction.producer),
              amount: Formatter.voteNumberFormat(transaction.votes)
            })}
          />

          <Hash hash={Formatter.txToUrl(transaction.result.tx_hash)} />
          <footer>
            <button className="large" onClick={() => onCancel()}>
              {t("完成")}
            </button>
          </footer>
        </>
      )}

      {transaction.status === "pending" && (
        <div className="pending">
          <StatusIcon status="pending" text={t("正在投票...")} />

          <div className="to jcsb">
            <span>{Formatter.voteNumberFormat(transaction.votes)}</span>
            <IconArrow />
            <span>{
              t("nodeName." + transaction.producer) === t("nodeName.") + transaction.producer ?
                t(transaction.producer) :
                t("nodeName." + transaction.producer)
            }</span>
          </div>
        </div>
      )}

      {transaction.status === "failed" && (
        <>
          <StatusIcon status="failed" text={t("投票失败")} />

          {transaction.failed !== "nogas" ? (
            <Hash hash={Formatter.txToUrl(transaction.failed.tx_hash)} />
          ) : (
              <div className="alertIost">
                <p className="alert aic">
                  <IconIOST />
                  {t("账号 Gas 不足")}
                </p>
                <div className="detail description">
                  <p>
                    {t(
                      "大约需要抵押 {{v}} IOST 才能完成此操作（如不足请适量增加）。",
                      { v: 5 }
                    )}
                  </p>
                  <p>
                    {t("TokenPocket 用户可前往「首页 - 资源」功能中完成抵押。")}
                  </p>
                </div>
              </div>
            )}
          <footer>
            <button className="large" onClick={() => onCancel()}>
              {t("完成")}
            </button>
          </footer>
        </>
      )}
    </Modal>
  );
};
