// 模态框
import React from "react";
import { useTransition, animated } from "react-spring";

import { ReactComponent as IconClose } from "../../styles/images/IconClose.svg";

export default ({ show, title, close, children, className }) => {
  const modalTransition = useTransition(show, null, {
    from: { opacity: 0, transform: "translate3d(0,1rem,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0,0)" },
    leave: {
      opacity: 0,
      transform: "translate3d(0,0.618rem,0)",
      pointerEvents: "none"
    }
  });

  const overlayTransition = useTransition(show, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, pointerEvents: "none" }
  });

  return (
    <>
      {overlayTransition.map(
        ({ item, key, props }) =>
          item && <animated.div key={key} style={props} className="overlay" />
      )}
      {modalTransition.map(
        ({ item, key, props }) =>
          item && (
            <animated.section
              key={key}
              style={props}
              className="modalContainer"
            >
              <div className="modal whiteBG scrolling">
                <header>
                  <span>{title}</span>
                  {close && (
                    <span className="close" onClick={close}>
                      <IconClose />
                    </span>
                  )}
                </header>
                {children}
              </div>
            </animated.section>
          )
      )}
    </>
  );
};
