import React, { useState, useEffect } from "react";
import Vote from "../libs/IOST/Vote";
import ProducerMetaData from "../data/produer";

import Header from "../components/Header";
import VoteManage from "../components/VoteManage";
import MyDividend from "../components/MyDividend";
import Footer from "../components/Footer";
import QA from "../components/QA";
import Banner from "../components/Banner";
import VoteRank from "../components/VoteRank";

const candidateBlackList = ["terra", "hemera", "chronus", "ourea", "pontus"];
const commonEarningsMetaData = [50, 50, 0, 0];
// accountInfo 投过票的列表 iost账户信息 
export default ({ accountInfo, iost, refreshDataCallback }) => {
  // console.log(`accountInfo${accountInfo},iost${iost},refreshDataCallback${refreshDataCallback}`)
  const [candidateList, setCandidateList] = useState([]);
  const [chainInfo, setChainInfo] = useState([]);
  const [totalVotes, setTotalVotes] = useState(0);
  const [avgAnnual, setAvgAnnual] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {

    if (accountInfo) {

      setTimeout(async () => {
        const vote = new Vote();
        const candidates = await vote.getProducerVoteInfoList("1");
        const chainInfo = await vote.getChainInfo();
        let candList = [];
        let totalVotes = 0;
        for (let candidate in candidates) {
          if (candidateBlackList.includes(candidate) === false) {
            // console.log(candidateBlackList)
            let votes = Number(Number(candidates[candidate]).toFixed(0));
            totalVotes += votes;
            const producerMetaData = findProducerMetaData(candidate);
            // console.log('h', producerMetaData)
            candList.push({
              candidate,
              name: producerMetaData.name,
              votes,
              metaData: producerMetaData.meta,
              tag: producerMetaData.tag,
              rank: producerMetaData.rank,
              annual: producerMetaData.annual,
              disable: producerMetaData.disable
            });


          }
        }
        // 按票数排序
        candList = candList.sort((a, b) => {
          return b.votes - a.votes;
        });
        // console.log(candList)
        // 按年化排序
        // candList = candList.sort((a, b) => {
        //   return b.annual - a.annual;
        // });

        // 按 Rank 排序
        candList = candList.sort((a, b) => {
          return b.rank - a.rank;
        });
        // 票数排序列表
        setCandidateList(candList);

        // 总票数
        setTotalVotes(totalVotes);
        // console.log(`chainInfo${chainInfo}`)？
        setChainInfo(chainInfo);
        // 总年化率
        let totalAnnual = 0;
        candList.map(function (item) {
          return (totalAnnual += item.annual);
        });
        // 平均年化率
        let avg = totalAnnual / candList.length / 100;
        setAvgAnnual(avg);
      }, 0);
    }
  }, [accountInfo]);

  function findProducerMetaData(producerName) {
    let producer = ProducerMetaData.find(producer => {
      return producer.account === producerName;
    });

    if (producer === undefined) {
      return {
        name: '',
        account: producerName,
        meta: commonEarningsMetaData,
        tag: [],
        rank: 1,
        annual: 7.8,
        disable: false
      };
    } else {
      return {
        name: producer.name,
        account: producer.account,
        meta: producer.meta ? producer.meta : commonEarningsMetaData,
        tag: producer.tag || [],
        rank: producer.rank ? producer.rank : 1,
        annual: producer.annual ? producer.annual : 7.8,
        disable: producer.disable ? producer.disable : false
      };
    }
  }

  function drawerOpenCallBack(value) {
    setDrawerOpen(value);
  }

  return (
    <>
      <Header />
      {/* 投票的列表 */}
      <VoteManage
        accountInfo={accountInfo}
        iost={iost}
        candidateList={candidateList}
        drawerOpenCallBack={drawerOpenCallBack}
      />
      <MyDividend
        accountInfo={accountInfo}
        iost={iost}
        refreshDataCallback={refreshDataCallback}
      />
      {/* 轮播图 */}
      <Banner />
      {/* 新闻 */}
      <QA />
      {/* 微信 */}
      <Footer />
      {/* 排行 */}
      <VoteRank
        accountInfo={accountInfo}
        iost={iost}
        chainInfo={chainInfo}
        candidateList={candidateList}
        totalVotes={totalVotes}
        drawerOpen={drawerOpen}
        avgAnnual={avgAnnual}
        drawerOpenCallBack={drawerOpenCallBack}
        refreshDataCallback={refreshDataCallback}
      />
    </>
  );
};
