import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as IconUpvote } from "../styles/images/IconUpvote.svg";

export default ({ onVote }, children) => {
  function Ad(props) {
    const { t } = useTranslation();

    return (
      <li
        key={props.nodeID}
        style={{ backgroundColor: props.color }}
        onClick={() => onVote(props.nodeID)}
      >
        {t("nodeName." + props.nodeID) === t("nodeName.") + props.nodeID ?
          t(props.nodeID) :
          t("nodeName." + props.nodeID)}

        {props.upvote && (
          <span className="badge">
            <IconUpvote />
          </span>
        )}
      </li>
    );
  }

  return (
    <section className="recommend">
      <ul className="scrolling">
        <Ad nodeID="tokenpocket" color="#3064df" upvote />
        <Ad nodeID="dappbirds66" color="#292474" upvote />
        <Ad nodeID="iostabc" color="#000" upvote />
      </ul>
    </section>
  );
};
