// 我的分红组件
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import VoteProducer from "../libs/IOST/VoteProducer";
import Formatter from "../utils/Formatter";
import NewDividendModal from "./NewDividendModal";
import NewRevoteModal from "./NewRevoteModal";
import ProducerMetaData from "../data/produer";
import ReactGA from 'react-ga';

export default ({ accountInfo, iost, refreshDataCallback }) => {
  const { t } = useTranslation();
  const [userVoteInfo, setUserVoteInfo] = useState();
  const [hasBonus, setHasBonus] = useState(false);
  const [hasBonusList, setHasBonusList] = useState(false);
  const [showBonusList, setShowBonusList] = useState(false);
  const [isShowNewDividendModal, setIsShowNewDividendModal] = useState(false);
  const [isShowNewRevoteModal, setIsShowNewRevoteModal] = useState(false);
  const [transaction, setTransaction] = useState({ status: "noTx" });

  const bonusListRef = useRef(null);

  const getVoteBonus = useCallback(() => {
    if (accountInfo) {
      setTimeout(async () => {
        const voteProducer = new VoteProducer();
        let userVoteInfo = await voteProducer.getVoterBonus1(accountInfo.name);
        for (let producerAccount in userVoteInfo.detail) {
          let producer = findProducerMetaData(producerAccount);
          userVoteInfo.detail[producerAccount] = {
            bonus: userVoteInfo.detail[producerAccount],
            name: producer.name
          };
        }

        setUserVoteInfo(userVoteInfo);
        setHasBonus(userVoteInfo.bonus > 0);
        setHasBonusList(Object.keys(userVoteInfo.detail).length > 0);
      }, 0);
    }
  }, [accountInfo]);

  useEffect(() => {
    getVoteBonus();
  }, [getVoteBonus]);

  function findProducerMetaData(producerName) {
    let producer = ProducerMetaData.find(producer => {
      return producer.account === producerName;
    });

    if (producer === undefined) {
      return {
        name: "",
        account: producerName
      };
    } else {
      return {
        name: producer.name,
        account: producer.account
      };
    }
  }

  function voterWithdraw() {
    const contractAddress = process.env.REACT_APP_VOTE_CONTRACT_ID;
    const account = accountInfo.name;

    const tx = iost.callABI(contractAddress, "voterWithdraw", [account]);
    tx.setGas(1, 2000000);
    iost
      .signAndSend(tx)
      .on("pending", pending => {
        console.log(pending, "pending");
        setTransaction({
          status: "pending",
          txId: pending
        });
        setIsShowNewDividendModal(true);
      })
      .on("success", result => {
        console.log(result, "result");
        setTransaction({
          status: "success",
          result
        });
        refreshDataCallback();
      })
      .on("failed", failed => {
        console.log(failed, "failed");
        if (failed === "User rejected the signature request") {
          return;
        }
        setTransaction({
          status: "failed",
          failed
        });
        setIsShowNewDividendModal(true);
      });
  }

  function revote() {
    const contractAddress = process.env.REACT_APP_VOTE_CONTRACT_ID;
    const account = accountInfo.name;

    let voteList = [];
    for (let producerAccount in userVoteInfo.detail) {
      voteList.push({
        candidate: producerAccount,
        amount: userVoteInfo.detail[producerAccount].bonus.toFixed(8)
      });
    }

    const tx = iost.callABI(contractAddress, "revote", [account, voteList]);
    tx.setGas(1, 2000000);
    iost
      .signAndSend(tx)
      .on("pending", pending => {
        console.log(pending, "pending");
        setTransaction({
          status: "pending",
          txId: pending
        });
        setIsShowNewRevoteModal(true);
      })
      .on("success", result => {
        console.log(result, "result");
        setTransaction({
          status: "success",
          result
        });

        ReactGA.event({
          category: "一键复投",
          action: account + "复投成功"
        });

        refreshDataCallback();
      })
      .on("failed", failed => {
        console.log(failed, "failed");
        if (failed === "User rejected the signature request") {
          return;
        }
        if (
          (typeof failed === "string" && failed.indexOf("gas not enough")) ||
          failed.message === "out of gas"
        ) {
          setTransaction({
            status: "failed",
            failed: "nogas"
          });

          return;
        }

        setTransaction({
          status: "failed",
          failed
        });

        ReactGA.event({
          category: "一键复投",
          action: account + "复投失败"
        });

        setIsShowNewRevoteModal(true);
      });
  }

  return (
    <>
      {transaction && (
        <NewDividendModal
          show={isShowNewDividendModal}
          onCancel={() => {
            setTransaction({ status: "noTx" });
            setIsShowNewDividendModal(false);
            getVoteBonus();
          }}
          transaction={transaction}
        />
      )}

      {userVoteInfo && (
        <NewRevoteModal
          show={isShowNewRevoteModal}
          onVote={() => revote()}
          onCancel={() => {
            setTransaction({ status: "noTx" });
            setIsShowNewRevoteModal(false);
            getVoteBonus();
          }}
          voteBonus={userVoteInfo.bonus}
          userVoteInfo={userVoteInfo}
          transaction={transaction}
        />
      )}

      <section className="panel myDivident">
        <p className="head">{t("我的分红")}</p>

        <p className="panelAmount">
          {userVoteInfo
            ? Formatter.iostFormat(userVoteInfo.bonus) + " IOST"
            : t("查询中...")}
        </p>

        {hasBonusList && (
          <>
            <div
              className="bonusList"
              ref={bonusListRef}
              style={{
                maxHeight: showBonusList
                  ? bonusListRef.current.scrollHeight + "px"
                  : 0
              }}
            >
              <header>
                <span>{t("分红节点")}</span>
              </header>
              <ul>
                <li className="detail">
                  {Object.keys(userVoteInfo.detail).map((key, index) => {
                    return <p>{t("nodeName." + key) === t("nodeName.") + key ?
                      t(key) :
                      t("nodeName." + key)}</p>;
                  })}
                </li>
              </ul>
            </div>

            <div
              className="detailUnfold"
              onClick={() => setShowBonusList(!showBonusList)}
            >
              {showBonusList ? t("收起") : t("展开")}
            </div>
          </>
        )}

        <button
          className="large"
          disabled={!hasBonus}
          onClick={() => setIsShowNewRevoteModal(true)}
        >
          {t("一键复投")}
        </button>

        <button
          className="large"
          disabled={!hasBonus}
          onClick={() => voterWithdraw()}
        >
          {t("一键领取")}
        </button>

        {/* <button className="large" onClick={() => voterWithdraw()}>
          {t("开通自动领取")}
        </button> */}
      </section>
    </>
  );
};
