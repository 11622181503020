import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as IconRight } from "../styles/images/IconRight.svg";
import { ReactComponent as Logo } from "../styles/images/Logo.svg";
import { ReactComponent as IconGlobe } from "../styles/images/IconGlobe.svg";

export default props => {
  const { t, i18n } = useTranslation();

  return (
    <section id="header" className="aic">
      {/* 点击切换路由 */}
      <Link to="/iostvoter">
        <div className="logo aic">
          {props.back ? 
          //props.back 传入了true 显示带有返回的头部
          (
            <>
              <span className="backIcon">
                <IconRight />
              </span>
              {t("返回")}
            </>
          )
          // 显示主页头
          : (
            <>
              <Logo className="logo" />
              {t("IOST 投票宝")}
            </>
          )}
        </div>
      </Link>
      <div className="lang">
        <span
          className="aic"
          onClick={() => {
            i18n.changeLanguage(i18n.language === "zh" ? "en" : "zh");
            localStorage.setItem("userLangStorage", i18n.language);
          }}
        >
          <IconGlobe />
          {i18n.language === "zh" ? "English" : "中文"}
        </span>
      </div>
    </section>
  );
};
