import IostHandler from './IostHandler'

export default class Vote {
  constructor () {
    this.contract = 'vote.iost'
    this.iostHandler = new IostHandler()
  }

  async getAdminId () {
    return await this.iostHandler.getTable(this.contract, 'adminID')
  }

  async getFundId () {
    return await this.iostHandler.getTable(this.contract, 'fundId')
  }

  async getCurrentId () {
    return await this.iostHandler.getTable(this.contract, 'current_id')
  }

  async getOwner (voteId) {
    return await this.iostHandler.getTable(this.contract, 'owner', voteId)
  }

  async getVoteInfo (voteId) {
    return await this.iostHandler.getTable(this.contract, 'voteInfo', voteId)
  }

  async getVoteToList (voteId, producer) {
    return await this.iostHandler.getTable(this.contract, `u_${voteId}`, producer)
  }

  async getOption (voteId, producer) {
    return await this.iostHandler.getTable(this.contract, `v_${voteId}`, producer)
  }

  async getProducerList (voteId) {
    return await this.iostHandler.getTable(this.contract, 'preOrder', voteId)
  }

  async getProducerVoteInfoList (voteId) {
    return await this.iostHandler.getTable(this.contract, 'preResult', voteId)
  }
  transfer(token, from, to, amount, memo){
    return this.iostHandler.transfer(token, from, to, amount, memo)
  }
  getAccount() {
    return this.iostHandler.getAccount()
  }

  getBalance(address) {
    return this.iostHandler.getBalance(address)
  }

  getChainInfo() {
    return this.iostHandler.getChainInfo();
  }

  async getAccountInfo(id) {
    return await this.iostHandler.getAccountInfo(id)
  }

  /**
   * 返回 account 给节点投票的列表
   * @param voteId
   * @param account
   * @returns {Promise<Array>}
   */
  // async getVote (voteId, account) {
  //   // this._checkVote(voteId);
  //
  //   let userVotes = await this.getVoteToList(voteId, account)
  //   if (!userVotes) {
  //     return []
  //   }
  //   let votes = []
  //   for (const o in userVotes) {
  //     votes.push({
  //       option: o,
  //       votes: new Float64(userVotes[o][0]).minus(userVotes[o][2]).toFixed(),
  //       voteTime: userVotes[o][1],
  //       clearedVotes: userVotes[o][2],
  //     })
  //   }
  //   return votes
  // }

  // topupVoterBonus () {
  //   const callbacks = {
  //     pending: (res) => {
  //       console.log('pending', res)
  //     },
  //     success: (res) => {
  //       console.log('success', res)
  //     },
  //     failed: (res) => {
  //       console.log('failed', res)
  //     },
  //   }
  //   const producer = process.env.REACT_APP_IOST_PRODUCER
  //   const payer = process.env.REACT_APP_IOST_ACCOUNT
  //   const amount = '100'
  //   this.iostHandler.topupVoterBonus(producer, amount, payer, callbacks)
  // }
}
