import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Modal from "../components/common/Modal";
import Formatter from "../utils/Formatter";
import StatusIcon from "./StatusIcon";
import Hash from "../components/Hash";

import { ReactComponent as IconGlobe } from "../styles/images/IconGlobe.svg";
import { ReactComponent as IconIOST } from "../styles/images/IconIOST.svg";

export default ({
  onConfirm,
  onCancel,
  currentRedeemProducer,
  voteList,
  transaction,
  show
}) => {
  const { t } = useTranslation();
  const [votes, setVotes] = useState();

  useEffect(() => {
    if (show) {
      setVotes();
    }
  }, [show]);

  function getDueDay() {
    const dueDay = new Date();
    dueDay.setDate(dueDay.getDate() + 3);

    return dueDay.toLocaleString();
  }

  return (
    <Modal
      show={show}
      title={t("赎回")}
      close={transaction.status !== "pending" ? () => onCancel() : null}
    >
      {transaction.status === "noTx" && (
        <>
          <div className="nodeInfo">
            <div className="name aic">
              <IconGlobe />
              <div>
                {t("nodeName." + currentRedeemProducer.option) === t("nodeName.") + currentRedeemProducer.option ?
                  t(currentRedeemProducer.option) :
                  t("nodeName." + currentRedeemProducer.option)}
                <p className="description">{currentRedeemProducer.option}</p>
              </div>
            </div>
          </div>

          <div className="input">
            <label>{t("输入你想赎回的票数")}</label>
            <input
              className="large"
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              value={votes || ""}
              onChange={event => setVotes(event.target.value)}
              max={currentRedeemProducer.votes}
              onBlur={() => {
                window.scrollTo(0, 0);
              }}
            />
            <p className="tips jcsb">
              <span>
                <span className="description">{t("最多")} </span>
                <span className="balance">{currentRedeemProducer.votes}</span>
              </span>
              <span
                className="action"
                onClick={() => setVotes(currentRedeemProducer.votes)}
              >
                {t("全部赎回")}
              </span>
            </p>
          </div>

          <div className="calc">
            <label>{t("预计到账时间")} </label>
            <p className="amount">{getDueDay()}</p>
          </div>

          <footer>
            <button className="large" onClick={() => onConfirm(votes)}>
              {t("赎回")}
            </button>
          </footer>
        </>
      )}

      {transaction.status === "success" && (
        <>
          <StatusIcon
            status="success"
            text={t("成功从 {{nodeName}} 赎回 {{amount}} 票", {
              nodeName: t("nodeName." + transaction.producer) === t("nodeName.") + transaction.producer ?
                t(transaction.producer) :
                t("nodeName." + transaction.producer),
              amount: Formatter.voteNumberFormat(transaction.votes)
            })}
          />

          <Hash hash={Formatter.txToUrl(transaction.result.tx_hash)} />
          <footer>
            <button className="large" onClick={() => onCancel()}>
              {t("完成")}
            </button>
          </footer>
        </>
      )}

      {transaction.status === "pending" && (
        <StatusIcon
          status="pending"
          text={t("正在从 {{nodeName}} 赎回 {{amount}} 票", {
            nodeName: t("nodeName." + transaction.producer) === t("nodeName.") + transaction.producer ?
              t(transaction.producer) :
              t("nodeName." + transaction.producer),
            amount: Formatter.voteNumberFormat(transaction.votes)
          })}
        />
      )}

      {transaction.status === "failed" && (
        <>
          <StatusIcon status="failed" text={t("赎回失败")} />

          {transaction.failed.tx_hash ? (
            <Hash hash={Formatter.txToUrl(transaction.failed.tx_hash)} />
          ) : (
              <div className="alertIost">
                <p className="alert aic">
                  <IconIOST />
                  {t("账号 Gas 不足")}
                </p>
              </div>
            )}

          <footer>
            <button className="large" onClick={() => onCancel()}>
              {t("完成")}
            </button>
          </footer>
        </>
      )}
    </Modal>
  );
};
