import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "react-spring";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { ReactComponent as IconAlert } from "../styles/images/IconAlert.svg";
import { ReactComponent as OnboardingWeChat } from "../styles/images/OnboardingWeChat.svg";
import { ReactComponent as OnboardingTelegram } from "../styles/images/OnboardingTelegram.svg";

export default () => {
  const { t } = useTranslation();
  const [copiedWeChat, setCopiedWeChat] = useState(false);
  const [copiedTelegram, setCopiedTelegram] = useState(false);

  const modalSpring = useSpring({
    opacity: 1,
    transform: "translate3d(0,0,0)",
    from: { opacity: 0, transform: "translate3d(0,0.618rem,0)" }
  });

  const overlaySpring = useSpring({
    opacity: 1,
    from: { opacity: 0 }
  });

  return (
    <>
      <animated.div style={overlaySpring} className="overlay" />
      <animated.section style={modalSpring} className="onboardingContainer">
        <div className="onboarding scrolling">
          <header className="aic">
            <div className="icon">
              <IconAlert />
            </div>
            <div>
              <p className="ohno">{t("oh no…")}</p>
              <p className="description">{t("未检测到 IOST 钱包")}</p>
            </div>
          </header>

          <p className="head">{t("常见问答")}</p>
          <ul className="qa">
            <li>
              <p className="question">{t("1. 手机上怎么用？")}</p>
              <p className="answer">
                {t(
                  "目前，IOST 投票宝仅可在最新「TokenPocket 钱包（企业版）」中使用。"
                )}
              </p>
            </li>
            <li>
              <p className="question">{t("2. 电脑上怎么用？")}</p>
              <p className="answer">
                {t(
                  "在 Chrome 网上应用店中搜索「iWallet」安装后，打开 vote.liebi.com 即可。"
                )}
              </p>
            </li>
          </ul>

          <p className="head">{t("联系我们")}</p>
          <ul className="contact">
            <li>
              <OnboardingWeChat />
              <div>
                <p className="way aic">{t("微信群")}</p>
                <p className="description">
                  {t("加客服微信，发送加群，我们会拉你入群。")}
                </p>
                <CopyToClipboard
                  text="LiebiService-1"
                  onCopy={() => {
                    setCopiedWeChat(true);
                    setTimeout(() => {
                      setCopiedWeChat(false);
                    }, 1000);
                  }}
                >
                  <button className="small ghost">
                    {copiedWeChat ? "已复制" : "复制客服微信"}
                  </button>
                </CopyToClipboard>
              </div>
            </li>
            <li>
              <OnboardingTelegram />
              <div>
                <p className="way aic">{t("Telegram 群")}</p>
                <p className="description">
                  {t("复制 Telegram 群链接到手机浏览器打开，然后加入。")}
                </p>
                <CopyToClipboard
                  text="https://t.me/liebi_en"
                  onCopy={() => {
                    setCopiedTelegram(true);
                    setTimeout(() => {
                      setCopiedTelegram(false);
                    }, 1000);
                  }}
                >
                  <button className="small ghost">
                    {copiedTelegram ? "已复制" : "复制 Telegram 群链接"}
                  </button>
                </CopyToClipboard>
              </div>
            </li>
          </ul>
        </div>
      </animated.section>
    </>
  );
};
