// 检测本地有iWallet 
import React from "react";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "react-spring";

import { ReactComponent as IconIOST } from "../styles/images/IconIOST.svg";

export default () => {
  const { t } = useTranslation();
  const modalSpring = useSpring({
    opacity: 1,
    transform: "translate3d(0,0,0)",
    from: { opacity: 0, transform: "translate3d(0,0.618rem,0)" }
  });

  const overlaySpring = useSpring({
    opacity: 1,
    from: { opacity: 0 }
  });

  return (
    <>
      <animated.div style={overlaySpring} className="overlay" />
      <animated.section style={modalSpring} className="onboardingContainer">
        <div className="walletTips scrolling">
          <header className="tac">
            <div className="icon">
              <IconIOST className="iost" />
            </div>
            <div>
              <p className="ohno">{t("请先解锁钱包")}</p>
            </div>
          </header>
        </div>
      </animated.section>
    </>
  );
};
