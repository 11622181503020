import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "react-spring";
import Formatter from "../utils/Formatter";
import NewVoteModal from "./NewVoteModal";
import Ad from "./Ad";
import Countdown from "react-countdown-now";
import ReactGA from 'react-ga';

import { ReactComponent as IconArrow } from "../styles/images/IconArrow.svg";
import { ReactComponent as IconPlus } from "../styles/images/IconPlus.svg";
import { ReactComponent as IconStatusPending } from "../styles/images/IconStatusPending.svg";
import { ReactComponent as IconOfficial } from "../styles/images/IconOfficial.svg";

export default ({
  accountInfo,
  iost,
  chainInfo,
  avgAnnual,
  totalVotes,
  candidateList,
  drawerOpen,
  drawerOpenCallBack,
  refreshDataCallback
}) => {
  const { t } = useTranslation();
  const [isShowMain, setIsShowMain] = useState(false);
  const [isShowNewVoteModal, setIsShowNewVoteModal] = useState(false);
  const [transaction, setTransaction] = useState({ status: "noTx" });
  const [currentCandidate, setCurrentCandidate] = useState({});

  useEffect(() => {
    try {
      document.createEvent("TouchEvent");
      if (drawerOpen) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          setIsShowMain(true);
        }, 755);
      } else {
        setIsShowMain(false);
      }
    } catch {
      setIsShowMain(true);
    }
  }, [drawerOpen]);

  function newVote(votes) {
    if (!votes || Number.isNaN(votes) || votes <= 0) return;

    const contractAddress = process.env.REACT_APP_VOTE_CONTRACT_ID;
    const producer = currentCandidate.candidate;
    const account = accountInfo.name;

    const tx = iost.callABI(contractAddress, "vote", [
      account,
      producer,
      Number(votes).toString()
    ]);
    tx.setGas(1, 2000000);
    iost
      .signAndSend(tx)
      .on("pending", pending => {
        console.log(pending, "pending");
        setTransaction({
          status: "pending",
          txId: pending,
          producer,
          votes
        });
      })
      .on("success", result => {
        console.log(result, "result");
        setTransaction({
          status: "success",
          producer,
          votes,
          result
        });

        ReactGA.event({
          category: "投票",
          action: account + "给" + producer + "投" + Number(votes).toString() + "票成功",
          label: "投票成功",
          value: Number(votes).toString()
        });

        refreshDataCallback();
      })
      .on("failed", failed => {
        console.log(failed, "failed");
        if (failed === "User rejected the signature request") return;
        if (
          (typeof failed === "string" && failed.indexOf("gas not enough")) ||
          failed.message === "out of gas"
        ) {
          setTransaction({
            status: "failed",
            producer,
            votes,
            failed: "nogas"
          });

          return;
        }

        setTransaction({
          status: "failed",
          producer,
          votes,
          failed
        });

        ReactGA.event({
          category: "投票",
          action: account + "给" + producer + "投" + Number(votes).toString() + "票失败",
          label: "投票失败",
          value: Number(votes).toString()
        });
      });
  }

  const rankMotion = useSpring({
    transform: `translate3d(0, ${
      drawerOpen ? -document.documentElement.clientHeight + 73.5 : -73.5
      }px, 0)`
  });

  const [scrollTopProps, setScrollTopProps] = useSpring(() => ({
    number: 0
  }));

  const goTop = () => {
    try {
      document.createEvent("TouchEvent");
      setScrollTopProps({
        reset: true,
        number: 0,
        from: { number: document.getElementById("rankList").scrollTop }
      });
    } catch {
      window.scrollTo(0, 0);
    }
  };

  const needCountDown = ["iostliebibp"];

  const timeLeft =
    Date.now() + ((172800 - (chainInfo.head_block % 172800)) / 2) * 1000;

  let countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (!completed) {
      let h = hours < 10 ? "0" : "";
      let m = minutes < 10 ? "0" : "";
      let s = seconds < 10 ? "0" : "";

      return (
        <span>
          {h}
          {hours}:{m}
          {minutes}:{s}
          {seconds}
        </span>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {accountInfo && (
        <NewVoteModal
          show={isShowNewVoteModal}
          onConfirm={votes => newVote(votes)}
          onCancel={() => {
            setTransaction({ status: "noTx" });
            setIsShowNewVoteModal(false);
          }}
          balance={accountInfo.balance}
          currentCandidate={currentCandidate}
          transaction={transaction}
        />
      )}

      <animated.section
        className={drawerOpen ? "rank open" : "rank"}
        style={rankMotion}
      >
        <header onClick={() => drawerOpenCallBack(!drawerOpen)}>
          <p className="head">
            <span>{t("分红排行榜")}</span>
            <IconPlus />
          </p>
        </header>

        <div className="goTop blur" onClick={goTop}>
          <IconArrow />
        </div>

        {isShowMain && (
          <animated.main
            scrollTop={scrollTopProps.number}
            id="rankList"
            className="scrolling"
          >
            <Ad
              onVote={account => {
                const candidate = candidateList.find(
                  item => item.candidate === account
                );
                if (candidate) {
                  setCurrentCandidate(candidate);
                  setIsShowNewVoteModal(true);
                }
              }}
            />

            <p className="total">
              <span>
                {t("全网总票数：") + Formatter.voteNumberFormat(totalVotes)}
                <span>
                  {t("平均年化：") + Number(avgAnnual * 100).toFixed(1) + "%"}
                </span>
              </span>
            </p>

            {candidateList
              .filter(item => !item.disable)
              .map((item, i) => (
                <div
                  className={
                    "node" +
                    (item.candidate === "iostliebibp" ? " official" : "")
                  }
                  key={i}
                >
                  {item.candidate === "iostliebibp" && (
                    <p className="officialBar">
                      <span className="official">
                        <IconOfficial />
                        {t("官方节点")}
                      </span>
                    </p>
                  )}
                  <div className="jcsb">
                    <div className="aic">
                      {/* <span className="ranking">{i + 1}</span> */}
                      {item.candidate ? (
                        <div className="name aic">
                          {t("nodeName." + item.candidate)===t("nodeName.") + item.candidate ?
                           t(item.candidate) : 
                           t("nodeName." + item.candidate)}
                          {item.tag.map((tag, j) => (
                            <span className="tag" key={j}>
                              {t(["nodeNameTag." + tag])}
                            </span>
                          ))}
                        </div>

                      ) : (
                          <span className="name">{item.candidate}</span>
                        )}
                    </div>
                    <button
                      className="small"
                      onClick={() => {
                        setCurrentCandidate(item);
                        setIsShowNewVoteModal(true);
                      }}
                    >
                      {t("投票")}
                    </button>
                  </div>
                  <div className="info">
                    <p className="amount">
                      {Formatter.voteNumberFormat(item.votes) + " " + t("票")} (
                      {((item.votes / totalVotes) * 100).toFixed(2)} %)
                    </p>
                    <ul className="chart aic">
                      <li>
                        {t("贡献分红")}
                        <span
                          style={{
                            backgroundSize: item.metaData[0] + "% 100%"
                          }}
                        />
                        {item.metaData[0] + "%"}
                      </li>
                      <li>
                        {t("当选分红")}
                        <span
                          style={{
                            backgroundSize: item.metaData[1] + "% 100%"
                          }}
                        />
                        {item.metaData[1] + "%"}
                      </li>
                      <li>
                        {t("出块分红")}
                        <span
                          style={{
                            backgroundSize: item.metaData[2] + "% 100%"
                          }}
                        />
                        {item.metaData[2] + "%"}
                      </li>
                      {/* 下线邀请分红 */}
                      {/* <li>
                        {t("邀请分红")}
                        <span
                          style={{
                            backgroundSize: item.metaData[3] + "% 100%"
                          }}
                        />
                        {item.metaData[3] + "%"}
                      </li> */}
                    </ul>
                    {needCountDown.find(node => node === item.candidate) && (
                      <p className="next aic">
                        <IconStatusPending />
                        <span>{t("距离下次分红")} </span>
                        <Countdown
                          date={timeLeft}
                          renderer={countdownRenderer}
                        />
                      </p>
                    )}
                  </div>
                </div>
              ))}
          </animated.main>
        )}
      </animated.section>
    </>
  );
};
