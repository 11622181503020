import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./i18n/i18n";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";

import "./styles/clear.css";
import "./styles/css.scss";
// 没有检测到钱包
import Onboarding from "./components/Onboarding";
// 检测本地钱包 请解锁钱包
import WalletTips from "./components/WalletTips";

import Main from "./pages/Main";
// 投票管理页面
import MyVotes from "./pages/MyVotes";
//投票
import Vote from "./libs/IOST/Vote";

import IOST from "iost";
// 节点列表
import ProducerMetaData from "./data/produer";
// import LaunchAD from "./components/LaunchAD";

export default () => {
  // 国际化语言
  const { t } = useTranslation();
  const [iost, setIost] = useState();
  const [accountInfo, setAccountInfo] = useState();
  const [onboardingST, setOnboardingST] = useState(false);
  const [walletTipsST, setWalletTipsST] = useState(false);

  let tryTimes = 25;

  useEffect(() => {
    // 下次新闻上线删掉
    localStorage.removeItem("userNewsStorage");

    let timer;
    // 检测本地是否有iWallet 25次
    function init() {
      tryTimes--;
      if (tryTimes > 0) {
        timer = setTimeout(() => {
          if (window.IWalletJS) {
            refreshDataCallback();
          } else {
            init();
          }
        }, 100);
      } else {
        refreshDataCallback();
      }
    }

    init();
    return () => {
      clearTimeout(timer);
    };
  }, [tryTimes]);

  function refreshDataCallback() {
    const vote = new Vote();
    if (window.IWalletJS) {
      console.log('IWallent:', window.IWalletJS)
      // window.IWalletJS 的enable()方法
      window.IWalletJS.enable()
        .then(async account => {
          const iost = window.IWalletJS.newIOST(IOST);
          // iost 账户信息
          setIost(iost);
          const accountInfo = await vote.getAccountInfo(account);
          // 获取本地用户的信息 投票信息列表
          let voteList = accountInfo.vote_infos;
          voteList = voteList.map(item => {
            const producerAccount = item.option;//列表的option属性
            const producer = ProducerMetaData.find(function (producer) {
              return producer.account === producerAccount;//匹配节点列表中的信息
            });
            item.name = producer ? producer.name : "";
            return item;
            // 返回用户投过票的列表
          });

          accountInfo.vote_infos = voteList;
          setAccountInfo(accountInfo);//状态改变 投过票的列表
        })
        .catch(error => {
          console.log(error);
          // 返回type locked 显示解锁组件
          if (error.type && error.type === "locked") {
            setWalletTipsST(true);
          }
        });
    } else {
      setOnboardingST(true); //本地没有检测到iwallent 就显示OnboardingST组件
    }
  }

  return (
    <Router>
      <Helmet title={t("IOST 投票宝")} />

      {/*<LaunchAD />*/}
      {/* 本地没有检测到iwallent 就显示OnboardingST组件 */}
      {onboardingST && <Onboarding />}
      {/* 检索到钱包但是没有解锁 */}
      {walletTipsST && <WalletTips />}
      {/* Switch 组件只会匹配一个路由 匹配成功将停止渲染 */}
      <Switch>
        {/* 主页面 */}
        <Route
          exact
          path="/iostvoter"
          render={props => (
            <Main
              {...props}
              iost={iost}
              accountInfo={accountInfo}
              refreshDataCallback={refreshDataCallback}
            />
          )}
        />
        <Route

          path="/iostvoter/myvotes"
          render={props => (
            <MyVotes
              {...props}
              iost={iost}
              accountInfo={accountInfo}
              refreshDataCallback={refreshDataCallback}
            />
          )}
        />
      </Switch>
    </Router>
  );
};
