import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { ReactComponent as WeChatLogo } from "../styles/images/WeChatLogo.svg";

export default () => {
  const { t } = useTranslation();

  const [wechatCopied, setWechatCopied] = useState(false);

  useEffect(() => {
    let timer;
    if (wechatCopied)
      timer = setTimeout(() => {
        setWechatCopied(false);
      }, 1000);
    return () => timer && clearTimeout(timer);
  }, [wechatCopied]);

  return (
    <section id="footer" className="jcsb">
      <div>
        <CopyToClipboard
          text="LiebiService-1"
          onCopy={() => {
            setWechatCopied(true);
          }}
        >
          <span className="aic">
            <WeChatLogo />
            {!wechatCopied ? t("微信：LiebiService-1") : t("已复制")}
          </span>
        </CopyToClipboard>
      </div>
    </section>
  );
};
