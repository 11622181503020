// 投票管理页面
import React, { useState, useEffect } from "react";
// tab 切换NavLink
import { Route, NavLink } from "react-router-dom";
// 国际化
import { useTranslation } from "react-i18next";
// 格式化 数据
import Formatter from "../utils/Formatter";

import ReactGA from 'react-ga';

import RedeemStatus from "../components/RedeemStatus";
import Header from "../components/Header";
import NewRedeemModal from "../components/NewRedeemModal";


export default ({ accountInfo, iost, refreshDataCallback }) => {
  const { t } = useTranslation();
  // 投票列表
  const [voteList, setVoteList] = useState([]);
  // isShowNewRedeemModal 模态框状态
  const [isShowNewRedeemModal, setIsShowNewRedeemModal] = useState(false);
  // item要赎回的节点
  const [currentRedeemProducer, setCurrentRedeemProducer] = useState(
    "something"
  );
  const [transaction, setTransaction] = useState({ status: "noTx" });

  useEffect(() => {
    // 用户投票的列表
    if (accountInfo) {
      setVoteList(accountInfo.vote_infos);
    }
  }, [accountInfo]);
  function unvote(votes) {
    if (!votes || Number.isNaN(votes) || votes <= 0) return;
    const contractAddress = process.env.REACT_APP_VOTE_CONTRACT_ID;
    const producer = currentRedeemProducer.option;
    const account = accountInfo.name;
    const tx = iost.callABI(contractAddress, "unvote", [
      account,
      producer,
      Number(votes).toString()
    ]);

    tx.setGas(1, 2000000);
    iost
      .signAndSend(tx)
      .on("pending", pending => {
        console.log(pending, "pending");
        setTransaction({
          status: "pending",
          txId: pending,
          producer,
          votes
        });
      })
      .on("success", result => {
        console.log(result, "result");
        setTransaction({
          status: "success",
          producer,
          votes,
          result
        });

        ReactGA.event({
          category: "赎回",
          action: account + "从" + producer + "赎回" + Number(votes).toString() + "票成功",
          label: "赎回失败",
          value: Number(votes).toString()
        });

        refreshDataCallback();
      })
      .on("failed", failed => {
        console.log('tx', tx)
        console.log(failed, "failed");
        if (failed === "User rejected the signature request") return;
        setTransaction({
          status: "failed",
          producer,
          votes,
          failed
        });

        ReactGA.event({
          category: "赎回",
          action: account + "从" + producer + "赎回" + Number(votes).toString() + "票失败",
          label: "赎回失败",
          value: Number(votes).toString()
        });
      });
  }

  return (
    <>
      {/* 模态框 */}
      <NewRedeemModal
        // show模态框状态
        show={isShowNewRedeemModal}
        voteList={voteList}
        currentRedeemProducer={currentRedeemProducer}
        // confirm() 方法用于显示一个带有指定消息和 OK 及取消按钮的对话框 确认返回true 取消返回false
        onConfirm={votes => unvote(votes)}
        onCancel={() => {
          setTransaction({ status: "noTx" });
          setIsShowNewRedeemModal(false);
        }}
        transaction={transaction}
      />

      <Header back={true} />

      <section className="myVotes">
        <p className="head">
          <NavLink to="/iostvoter/myvotes/current">{t("当前投票")}</NavLink>
          <NavLink to="/iostvoter/myvotes/history">{t("正在赎回")}</NavLink>

        </p>
        <Route
          exact
          path="/iostvoter/myvotes/current"
          render={() =>
            voteList &&
            voteList.map((item, index) => (
              <div className="myVoteItem jcsb" key={index}>
                <div>
                  <p className="name">
                    {t("nodeName." + item.option) === t("nodeName.") + item.option ?
                      t(item.option) :
                      t("nodeName." + item.option)}
                  </p>
                  <p className="info">
                    <span className="amount">
                      {Formatter.voteNumberFormat(item.votes) + " " + t("票")}
                    </span>
                  </p>
                  {/* <p className="info account">
                    {t("节点账号：")}{" "}
                    {item.option}
                  </p> */}
                </div>
                <button
                  className="medium white"
                  onClick={() => {
                    setCurrentRedeemProducer(item);
                    // IsShowNewRedeemModal状态改为true
                    setIsShowNewRedeemModal(true);
                  }}
                >
                  {t("赎回")}
                </button>
              </div>
            ))
          }
        />

        <Route
          exact
          path="/iostvoter/myvotes/history"
          render={() =>
            accountInfo &&
            accountInfo.frozen_balances && (
              <RedeemStatus accountInfo={accountInfo} />
            )
          }
        />

      </section>
    </>
  );
};
