import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default ({ hash }) => {
  const { t } = useTranslation();
  const [hashCopied, setHashCopied] = useState(false);

  useEffect(() => {
    let timer;
    if (hashCopied)
      timer = setTimeout(() => {
        setHashCopied(false);
      }, 1000);
    return () => timer && clearTimeout(timer);
  }, [hashCopied]);

  return (
    <CopyToClipboard
      text={hash}
      onCopy={() => {
        setHashCopied(true);
      }}
    >
      <section className="hash">
        <label>{hashCopied ? t("已复制") : t("交易 Hash（点击复制）")}</label>
        <p>{hash}</p>
      </section>
    </CopyToClipboard>
  );
};
