import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "react-spring";
import { ReactComponent as IconQuestion } from "../styles/images/IconQuestion.svg";

function QAItem({ q, a }) {
  const [expand, setExpand] = useState(false);

  const answerRef = useRef(null);

  const answerMotion = useSpring({
    height: expand ? answerRef.current.scrollHeight : 0,
    opacity: expand ? 1 : 0
  });

  return (
    <li onClick={() => setExpand(!expand)}>
      <IconQuestion />
      <div>
        <p className={expand ? "question expand" : "question"}>{q}</p>
        <animated.div
          key={a}
          ref={answerRef}
          style={answerMotion}
          className="answer description"
        >
          <p>{a}</p>
        </animated.div>
      </div>
    </li>
  );
}

export default () => {
  const { t } = useTranslation();

  return (
    <section className="panel qaPanel">
      <p className="head">{t("常见问答")}</p>
      <ul>
        <QAItem
          q={t("通过投票宝投票安全吗？")}
          a={t(
            "是的，100% 安全，IOST 的投票无需转出 IOST，所有投票动作均在链上完成，透明可查，掌管属于自己的 IOST。"
          )}
        />

        <QAItem
          q={t("每天都可以领取分红吗？")}
          a={t(
            "是的，根据 IOST 公链规则，所有节点都必须将非出块奖励的 50% 发放给投票人，所以非出块奖励的50%分红是节点无法干预由系统自动完成的。但除此之外节点的额外分红，发放的数量与时间由节点自身定义。"
          )}
        />

        <QAItem
          q={t("每日分红是每 24 小时分红吗？")}
          a={t(
            "不是，是每出 172,800 个块分红一次，IOST 出块速度为 2 块/秒，所以出 172,800 个块的时间约等于 24 小时。但其中可能出块丢块的情况，造成总出块时间延长，所以每日分红不是标准的 24 小时（可能超出 0 ～ 30 分钟）。"
          )}
        />

        <QAItem
          q={t("我的票都投在 BISS 币市交易所，如何领取分红呢？")}
          a={t(
            "BISS 币市是我们的合作伙伴，由节点发放的额外分红，会在次日由 BISS 代发到您的 BISS 账户。"
          )}
        />
      </ul>
    </section>
  );
};
