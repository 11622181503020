export default class Formatter {
  static iostFormat(number) {
    // number = Number(Number(number).toFixed(8));
    let minimumFractionDigits = 0;
    let maximumFractionDigits = 4;
    return new Intl.NumberFormat("en", {
      minimumFractionDigits,
      maximumFractionDigits
    }).format(number);
  }

  static voteNumberFormat(number) {
    let minimumFractionDigits = 0;
    let maximumFractionDigits = 4;
    return new Intl.NumberFormat("en", {
      minimumFractionDigits,
      maximumFractionDigits
    }).format(number);
  }

  static balanceNumberFormat(number) {
    let minimumFractionDigits = 0;
    let maximumFractionDigits = 8;
    return new Intl.NumberFormat("en", {
      minimumFractionDigits,
      maximumFractionDigits
    }).format(number);
  }

  static txToUrl(txHash) {
    return `${process.env.REACT_APP_IOST_BROWSER}/tx/${txHash}`
  }
}
