import React from "react";
import { useTranslation } from "react-i18next";

import Modal from "../components/common/Modal";
import Formatter from "../utils/Formatter";
import StatusIcon from "./StatusIcon";
import Hash from "../components/Hash";

import { ReactComponent as IconIOST } from "../styles/images/IconIOST.svg";

export default ({ onCancel, transaction, show }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      title={t("领取分红")}
      close={transaction.status !== "pending" ? () => onCancel() : null}
    >
      {transaction.status === "success" && (
        <>
          <StatusIcon status="success" text={t("成功领取分红")} />

          <Hash hash={Formatter.txToUrl(transaction.result.tx_hash)} />
          <footer>
            <button className="large" onClick={() => onCancel()}>
              {t("完成")}
            </button>
          </footer>
        </>
      )}
      {transaction.status === "pending" && (
        <StatusIcon status="pending" text={t("正在领取分红 ...")} />
      )}
      {transaction.status === "failed" && (
        <>
          <StatusIcon status="failed" text={t("领取失败")} />

          {transaction.failed.tx_hash ? <Hash hash={Formatter.txToUrl(transaction.failed.tx_hash)} /> : (<div className="alertIost">
            <p className="alert aic">
              <IconIOST />
              {t("账号 Gas 不足")}
            </p>
          </div>)}

          <footer>
            <button className="large" onClick={() => onCancel()}>
              {t("完成")}
            </button>
          </footer>
        </>
      )}
    </Modal>
  );
};
