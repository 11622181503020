const IOST = require('iost')

export default class IostHandler {
  constructor () {
    // init iost sdk
    this.iost = new IOST.IOST({
      // will use default setting if not set
      gasRatio: 1,
      gasLimit: 4000000,
      delay: 0,
      expiration: 90,
      defaultLimit: 'unlimited',
    })
    this.rpc = new IOST.RPC(new IOST.HTTPProvider(process.env.REACT_APP_IOST_HOST))
    this.iost.setRPC(this.rpc);
  }

  async getAccount() {
    return this.iost.currentAccount
  }

  async getBalance(address, tokenSymbol = "iost", useLongestChain = 0) {
    return await this.rpc.blockchain.getBalance(address, tokenSymbol, useLongestChain)
  }

  async getAccountInfo(id, reversible = true) {
    return await this.rpc.blockchain.getAccountInfo(id, reversible)
  }

  async getTable(contractId, key, field = "", by_longest_chain = true) {
    let response = await this.rpc.blockchain.getContractStorage(contractId, key, field, by_longest_chain)
    return JSON.parse(response.data)
  }

  async getChainInfo() {
    return await this.rpc.blockchain.getChainInfo();
  }

  async getCandidateBonus(candidate, by_longest_chain = true) {
    let _provider = this.rpc.getProvider()
    const name = candidate;
    const api = `getCandidateBonus/${name}/${by_longest_chain}`;

    return _provider.send('get', api, null);
  }

  async getVoterBonus(voter, by_longest_chain = true) {
    let _provider = this.rpc.getProvider()
    const name = voter;
    const api = `getVoterBonus/${name}/${by_longest_chain}`;

    return _provider.send('get', api, null);
  }

  callABI (contract, abi, args, callbacks) {
    const tx = this.iost.callABI(contract, abi, args)
    this.iost.signAndSend(tx)
    .on('pending', callbacks.pending)
    .on('success', callbacks.success)
    .on('failed', callbacks.failed)

    return tx
  }
  transfer(token, from, to, amount, memo,callbacks){
    const tx =this.iost.transfer(token, from, to, amount, memo)
    this.iost.signAndSend(tx)
    .on("pending", console.log)
    .on("success", console.log)
    .on("failed", console.log);
    return tx
  }
  topupVoterBonus(producer, amount, payer, callbacks) {
    const args = [producer, amount, payer]
    const tx = this.callABI('vote_producer.iost', 'topupVoterBonus', args, callbacks)

    return tx
  }
}

